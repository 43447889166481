<template>
  <div>
    <section style="background-color: var(--white);" class="selfcare-title d-flex">

    </section>

    <div style="background-color: var(--gray-white);" class="promotions">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-12 mb-4" v-for="(promotion, index) in promotions" :key="index">
            <div class="card">
              <img style="border-bottom-left-radius: 7px;border-bottom-right-radius: 7px;"
                :src="`/img/carousel/` + promotion.img" class="card-img-top img-fluid" alt="Promotion image">
              <div class="card-body">
                <router-link to="/deposit" style="float: right;background-color: var(--darkBackground);color: var(--yellow);"
                  class="btn">
                  Deposit
                </router-link>
                <!-- <router-link :to="{ path: '/terms', hash: '#specific' }"
                  style="float: right; color: var(--darkBackground);" class="btn">
                  <span style="text-decoration: underline;">More Info</span>
                </router-link> -->

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  name: 'Offers',
  data() {
    return {
      promotions: [
        {
          img: "BANNER01.jpg",
          link: "/register",
          title: "PROMOS TITLE",
          description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quisquam consequatur necessitatibus eaque",
        },
        {
          img: "BANNER02.jpg",
          link: "/deposit",
          title: "PROMOS TITLE",
          description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quisquam consequatur necessitatibus eaque",
        },
      ],
    };
  },
  components: {},
  mounted: function () {

    this.$store.dispatch("setCurrentPage", "offers");
    this.reloadProfile();

  }

}
</script>
